import React from 'react';
import "@popperjs/core/dist/umd/popper.min"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"
import './src/styles/template.scss'


import { PrismicProvider } from '@prismicio/react'
import { client } from './prismic'

export const wrapRootElement = ({ element }) => {
    return (
      <React.StrictMode>
        <PrismicProvider client={client}>
          {element}
          </PrismicProvider>
      </React.StrictMode>
    );
  }